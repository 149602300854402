import {
  Box,
  Button,
  FacebookIcon,
  HelthjemLogo,
  InstagramIcon,
  MastercardIcon,
  MessengerIcon,
  PostenLogo,
  PostnordLogo,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  VisaIcon
} from '@hermes/web-components'
import { useIntercom } from '@hooks/useIntercom'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import { getLanguageWithVersionFromLocale } from '@lib/utils/getLangFromLocale'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import Link from 'next/link'
import {
  FooterColumn,
  FooterContainer,
  FooterLinks,
  PaymentMethod,
  PaymentMethods,
  SocialsContainer
} from './Footer.styles'
import SubFooter from './SubFooter'

export const Footer = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslateMessage()
  const locale = useLocale()
  const region = getRegionFromLocale(locale)
  const { show } = useIntercom()
  const language = getLanguageWithVersionFromLocale(locale)

  const getStartedLinks = [
    {
      href: `/${locale}/become-seller`,
      title: t({ id: 'footer.getStarted.learnToSell', defaultMessage: 'Learn to sell' })
    },
    {
      href: `/${locale}/buy-used`,
      title: t({ id: 'footer.getStarted.learnToBuy', defaultMessage: 'Learn to buy' })
    },
    { href: `/${locale}/auth`, title: t({ id: 'footer.getStarted.auth', defaultMessage: 'Sign up' }) },
    {
      href: `/${locale}/gift-card`,
      title: t({ id: 'footer.getStarted.giftCards', defaultMessage: 'Buy a gift card' })
    },
    {
      href: `/${locale}/b/royalties-payout`,
      title: t({ id: 'footer.getStarted.forTheAuthors', defaultMessage: 'For forfattere' })
    }
  ]
  const supportLinks = [
    { href: `https://help.bookis.com/${language}`, title: t({ id: 'help', defaultMessage: 'Help' }) },
    {
      href: `/${locale}/request/track`,
      title: t({ id: 'footer.help.trackShipments', defaultMessage: 'Track a shipment' })
    },
    {
      href: `/${locale}/pages/terms-conditions`,
      title: t({ id: 'footer.help.termsOfUse', defaultMessage: 'Terms of use' })
    },
    {
      href: `/${locale}/pages/privacy`,
      title: t({ id: 'footer.help.privacyPolicy', defaultMessage: 'Privacy policy' })
    },
    {
      href: `/${locale}/pages/cookies`,
      title: t({ id: 'footer.help.cookiesPolicy', defaultMessage: 'Cookies policy' })
    }
  ]
  const companyLinks = [
    {
      href: `/${locale}/b/velkommen-til-bookis`,
      title: t({ id: 'footer.company.about', defaultMessage: 'About us' })
    },
    {
      href: '',
      action: () => show(),
      title: t({ id: 'footer.company.contact', defaultMessage: 'Contact us' })
    },
    { href: `/${locale}/pages/career`, title: t({ id: 'footer.company.work', defaultMessage: 'Work with us' }) },
    { href: `/${locale}/feedback`, title: t({ id: 'give.feedback' }) }
  ]
  const socialsLinks = [
    {
      icon: <FacebookIcon width={18} />,
      ariaLabel: 'company-facebook-social',
      href: `https://www.facebook.com/${region === 'se' ? 'bookis.sverige' : 'bookisofficial'}`
    },
    {
      icon: <MessengerIcon width={18} />,
      ariaLabel: 'company-messenger-social',
      href: `https://www.messenger.com/t/${region === 'se' ? 'bookis.sverige' : 'bookisofficial'}`
    },
    {
      icon: <InstagramIcon width={18} />,
      ariaLabel: 'company-instagram-social',
      href: `https://www.instagram.com/${region === 'se' ? 'bookis.sverige' : 'bookisofficial'}`
    }
  ]

  const Socials = () => (
    <FooterColumn>
      <Typography fontSize="14px" lineHeight="20px" fontWeight="400" mb="24px">
        {t({ id: 'footer.socials' })}
      </Typography>
      <SocialsContainer>
        {socialsLinks.map((link) => (
          <a key={link.ariaLabel} href={link.href} target="_blank" rel="noreferrer">
            <Button
              aria-label={link.ariaLabel}
              variant="iconOutlined"
              sx={{
                width: '32px',
                height: '32px'
              }}
            >
              {link.icon}
            </Button>
          </a>
        ))}
      </SocialsContainer>
    </FooterColumn>
  )
  const Links = ({
    title,
    links
  }: {
    title: string
    links: { href: string; title: string; action?: () => void }[]
  }) => (
    <FooterColumn>
      <Typography fontWeight="500" fontSize="18px" lineHeight="24px" mb="16px">
        {title}
      </Typography>
      {links.map((link, index) => (
        <FooterLinks key={index}>
          {link.action ? (
            <Typography onClick={link.action}>{link.title}</Typography>
          ) : (
            <Link href={link.href}>{link.title}</Link>
          )}
        </FooterLinks>
      ))}
    </FooterColumn>
  )
  const DeliveryAndPayment = () => (
    <FooterColumn>
      <Typography fontSize="14px" lineHeight="20px" fontWeight="400" mb="10px">
        {t({ id: 'footer.delivery' })}
      </Typography>
      <Box mb="40px">
        {region === 'no' ? (
          <Stack direction="column" spacing={1}>
            <HelthjemLogo width="115px" />
            <PostenLogo width="115px" />
          </Stack>
        ) : (
          <PostnordLogo width="115px" />
        )}
      </Box>
      <Typography fontSize="14px" lineHeight="20px" fontWeight="400">
        {t({ id: 'footer.payments' })}
      </Typography>
      <PaymentMethods>
        <PaymentMethod>
          <VisaIcon height="24px" />
        </PaymentMethod>
        <PaymentMethod>
          <MastercardIcon height="24px" />
        </PaymentMethod>
      </PaymentMethods>
    </FooterColumn>
  )

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff'
      }}
    >
      <FooterContainer>
        {!isMobile && <Socials />}
        <Links title={t({ id: 'footer.getStarted' })} links={getStartedLinks} />
        <Links title={t({ id: 'support' })} links={supportLinks} />
        <Links title={t({ id: 'company' })} links={companyLinks} />
        <DeliveryAndPayment />
        {isMobile && <Socials />}
      </FooterContainer>
      <SubFooter />
    </Box>
  )
}
