import React, { SVGProps } from 'react'

const Facebook = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.00073 0.378906C3.79349 0.378906 0.382446 3.78995 0.382446 7.99719C0.382446 11.7991 3.16797 14.9503 6.81064 15.524V10.1999H4.8754V7.99719H6.81064V6.31872C6.81064 4.40786 7.94816 3.35414 9.68759 3.35414C10.5211 3.35414 11.3943 3.50272 11.3943 3.50272V5.37624H10.4312C9.48645 5.37624 9.19083 5.96443 9.19083 6.5671V7.99567H11.3021L10.9645 10.1983H9.19083V15.5225C12.8335 14.9519 15.619 11.7999 15.619 7.99719C15.619 3.78995 12.208 0.378906 8.00073 0.378906Z"
      fill="#415993"
    />
  </svg>
)

export default Facebook
