import React, { SVGProps } from 'react'

const SeFlag = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" {...props}>
    <path fill="#066aa7" d="M0 0h640v480H0z" />
    <path fill="#fecc00" d="M0 192h640v96H0z" />
    <path fill="#fecc00" d="M176 0h96v480h-96z" />
  </svg>
)

export default SeFlag
