import React, { SVGProps } from 'react'

const Messenger = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="8.99997" cy="8.99814" rx="8.38095" ry="8.38095" fill="#2196F3" />
    <path
      d="M9.0095 4.42667C6.54371 4.37805 4.50332 6.33343 4.44702 8.79904C4.45346 10.0541 5.0092 11.2435 5.96784 12.0536V13.3615C5.96784 13.4665 6.05296 13.5516 6.15795 13.5516C6.19358 13.5516 6.22849 13.5416 6.2587 13.5227L7.31834 12.8608C7.85821 13.0673 8.43149 13.1726 9.0095 13.1714C11.4753 13.22 13.5157 11.2647 13.572 8.79906C13.5157 6.33343 11.4753 4.37805 9.0095 4.42667Z"
      fill="white"
    />
    <path
      d="M12.0132 7.54518C11.9566 7.46937 11.8529 7.44668 11.7698 7.49195L9.79277 8.56983L8.56281 7.51513C8.48739 7.45047 8.37489 7.45477 8.30466 7.52502L6.02342 9.80626C5.9494 9.88073 5.94976 10.0011 6.02424 10.0751C6.08378 10.1343 6.17508 10.1474 6.24888 10.1074L8.22595 9.0295L9.45706 10.0846C9.53248 10.1492 9.64497 10.1449 9.71521 10.0747L11.9965 7.79344C12.0629 7.72634 12.0701 7.6206 12.0132 7.54518Z"
      fill="#2196F3"
    />
  </svg>
)

export default Messenger
