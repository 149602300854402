import React, { SVGProps } from 'react'

const Visa = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="3.5" width="23" height="17" rx="1.5" fill="white" stroke="#C1C7CF" />
    <path
      d="M10.0749 9.59133L7.98019 14.5892H6.61352L5.58269 10.6007C5.5201 10.355 5.46569 10.265 5.27535 10.1615C4.9646 9.99292 4.45144 9.83475 3.99994 9.73658L4.03061 9.59133H6.23052C6.51094 9.59133 6.76302 9.778 6.82669 10.1009L7.3711 12.9929L8.7166 9.59125H10.0749V9.59133ZM15.4298 12.9574C15.4353 11.6383 13.6058 11.5657 13.6183 10.9764C13.6223 10.7971 13.793 10.6064 14.1668 10.5577C14.352 10.5335 14.8624 10.515 15.4413 10.7814L15.6684 9.72158C15.3573 9.60867 14.9571 9.5 14.4591 9.5C13.1812 9.5 12.2818 10.1793 12.2743 11.1521C12.266 11.8716 12.9162 12.2731 13.406 12.5122C13.9099 12.757 14.079 12.9143 14.0771 13.1333C14.0735 13.4686 13.6752 13.6166 13.3029 13.6224C12.6531 13.6324 12.276 13.4466 11.9754 13.3068L11.7411 14.4017C12.0432 14.5403 12.6008 14.6612 13.1788 14.6673C14.5371 14.6673 15.4256 13.9964 15.4298 12.9574ZM18.8043 14.5892H20L18.9563 9.59133H17.8526C17.6044 9.59133 17.3951 9.73583 17.3024 9.958L15.3623 14.5892H16.7199L16.9894 13.8427H18.6482L18.8043 14.5892ZM17.3617 12.8183L18.0422 10.9418L18.4338 12.8183H17.3617ZM11.9222 9.59133L10.8531 14.5892H9.56027L10.6298 9.59133H11.9222V9.59133Z"
      fill="#1434CB"
    />
  </svg>
)

export default Visa
