import React, { SVGProps } from 'react'

export const PostenLogo = (props?: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.85 41.02" preserveAspectRatio="xMidYMid meet" {...props}>
    <g id="layer1" fillOpacity="1" fillRule="nonzero" stroke="none" transform="translate(-121.257 -455.312)">
      <path
        id="path32"
        fill="#e32d22"
        d="M202.794 481.114c-1.645-.1-2.722-.1-2.655-1.948.033-1.077.31-1.848 3.57-1.748 2.218.068 2.913.538 2.913 2.118h3.495c0-3.9-2.375-5.11-6.44-5.175-4.37-.068-7.267.84-7.335 4.906-.1 5.041 3.663 4.906 7.325 5.107 1.883.1 3.024.134 2.958 2.083-.034 1.177-.419 1.916-3.679 1.816-2.687-.067-3.18-.74-3.18-2.151h-3.497c0 4.032 2.577 5.107 6.71 5.175 4.403.067 7.342-.974 7.41-5.008.1-5.24-3.832-4.938-7.595-5.175m16.05 7.092c-1.848-.035-2.072-.97-2.072-2.22v-8.05h7.468v-3.277h-7.468v-5.3h-3.797v5.3h-2.21v3.278h2.21v8.05c0 3.664 2.184 5.31 5.982 5.31 3.26 0 5.328-1.243 5.328-5.175h-3.449c-.039 1.046-.277 2.116-1.991 2.084m30.909-13.845c-2.32 0-3.797.738-4.738 1.578v-1.28h-3.294v16.37h3.798v-7.63c0-2.856-.304-5.678 3.797-5.678 3.293 0 2.99 1.512 2.99 4.67v8.637h3.8v-8.939c0-3.562.268-7.728-6.353-7.728m-20.76 6.82c0-2.183.336-3.695 3.663-3.695 3.53 0 3.73 1.345 3.73 3.696zm3.663-6.82c-6.587 0-7.527 3.762-7.527 8.668 0 4.773 1.042 8.268 7.627 8.268 3.092 0 7.193-.773 7.193-5.175h-3.675c-.033 1.849-2.006 2.05-3.383 2.05-3.26 0-3.966-1.109-3.966-3.899h11.225c0-5.711-.336-9.912-7.494-9.912m-45.238 13.576c-3.664 0-4-1.411-4-5.109 0-3.696.336-5.107 4-5.107 3.662 0 4 1.41 4 5.107s-.338 5.109-4 5.109m0-13.576c-2.655 0-4.638.47-6.05 1.848-1.444 1.377-1.747 3.494-1.747 6.62s.303 5.242 1.748 6.62c1.411 1.378 3.394 1.848 6.049 1.848 2.62 0 4.637-.47 6.05-1.849 1.443-1.377 1.745-3.494 1.745-6.62s-.302-5.242-1.745-6.619c-1.413-1.378-3.43-1.848-6.05-1.848m-16.394 13.576c-3.26 0-4.168-.975-4.168-5.074 0-4.101.908-5.142 4.168-5.142 2.822 0 3.427 1.31 3.427 5.142 0 3.83-.605 5.074-3.427 5.074m.605-13.576c-1.749-.034-3.73.368-5.109 1.578v-1.28h-3.427v21.673h3.797v-6.481c1.109 1.177 2.52 1.446 4.301 1.446 6.35 0 7.054-4.47 7.054-8.434 0-5.21-.772-8.436-6.616-8.502"
      ></path>
      <path
        id="path36"
        fill="#e32d22"
        d="M156.923 474.659c-.38 4.789-4.383 8.557-9.27 8.557-4.885 0-8.89-3.768-9.268-8.557h-17.127c.39 9.929 8.558 17.859 18.583 17.859s18.193-7.93 18.584-17.86z"
      ></path>
      <path
        id="path40"
        fill="#bcbdbf"
        d="M138.385 473.17c.379-4.788 4.383-8.557 9.269-8.557s8.89 3.769 9.269 8.558h1.502c-.391-9.929-8.56-17.859-18.584-17.859-10.025 0-18.192 7.93-18.584 17.859z"
      ></path>
    </g>
  </svg>
)
