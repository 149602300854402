import React, { SVGProps } from 'react'

const Mastercard = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="3.5" width="23" height="17" rx="1.5" fill="white" stroke="#C1C7CF" />
    <path d="M13.8929 8.42499H10.1073V15.2279H13.8929V8.42499Z" fill="#FF5F00" />
    <path
      d="M10.3477 11.8265C10.3471 11.1713 10.4955 10.5246 10.7818 9.93527C11.0681 9.34595 11.4847 8.8295 12.0001 8.425C11.3619 7.92333 10.5954 7.61134 9.78818 7.52471C8.98101 7.43808 8.16575 7.58028 7.43558 7.93508C6.70541 8.28989 6.08979 8.84296 5.6591 9.5311C5.2284 10.2192 5 11.0147 5 11.8265C5 12.6383 5.2284 13.4337 5.6591 14.1218C6.08979 14.81 6.70541 15.3631 7.43558 15.7179C8.16575 16.0727 8.98101 16.2149 9.78818 16.1282C10.5954 16.0416 11.3619 15.7296 12.0001 15.2279C11.4847 14.8234 11.0681 14.307 10.7818 13.7177C10.4955 13.1284 10.3471 12.4816 10.3477 11.8265V11.8265Z"
      fill="#EB001B"
    />
    <path
      d="M19 11.8265C19 12.6383 18.7717 13.4337 18.341 14.1218C17.9103 14.81 17.2947 15.363 16.5646 15.7178C15.8344 16.0727 15.0192 16.2149 14.212 16.1282C13.4048 16.0416 12.6383 15.7296 12.0001 15.2279C12.5151 14.823 12.9314 14.3065 13.2176 13.7173C13.5038 13.1281 13.6526 12.4815 13.6526 11.8265C13.6526 11.1714 13.5038 10.5249 13.2176 9.93566C12.9314 9.34645 12.5151 8.82991 12.0001 8.425C12.6383 7.92333 13.4048 7.61134 14.212 7.52471C15.0192 7.43808 15.8344 7.58029 16.5646 7.93509C17.2947 8.2899 17.9103 8.84298 18.341 9.53111C18.7717 10.2192 19 11.0147 19 11.8265V11.8265Z"
      fill="#F79E1B"
    />
    <path
      d="M18.5872 14.5074V14.3681H18.6434V14.3398H18.5004V14.3681H18.5566V14.5074H18.5872ZM18.8649 14.5074V14.3395H18.8211L18.7706 14.455L18.7202 14.3395H18.6763V14.5074H18.7073V14.3807L18.7546 14.4899H18.7867L18.834 14.3805V14.5074H18.8649Z"
      fill="#F79E1B"
    />
  </svg>
)

export default Mastercard
