import React, { SVGProps } from 'react'

const Instagram = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8.99997" cy="8.99814" r="8.38095" fill="url(#paint0_linear_2202_19554)" />
    <path
      d="M8.99895 4.49653C7.15463 4.49653 5.40327 4.33247 4.73432 6.04929C4.458 6.75837 4.49813 7.67926 4.49813 8.99787C4.49813 10.1549 4.46105 11.2424 4.73432 11.9459C5.40175 13.6638 7.16733 13.4992 8.99794 13.4992C10.764 13.4992 12.585 13.6831 13.2621 11.9459C13.5389 11.2297 13.4983 10.3226 13.4983 8.99787C13.4983 7.23939 13.5953 6.10415 12.7424 5.25183C11.879 4.38834 10.7112 4.49653 8.99692 4.49653H8.99895ZM8.59565 5.30771C12.4428 5.30161 12.9324 4.87393 12.6622 10.8153C12.5662 12.9166 10.9662 12.686 8.99946 12.686C5.41343 12.686 5.31032 12.5834 5.31032 8.99583C5.31032 5.36663 5.59476 5.30974 8.59565 5.30669V5.30771ZM11.4015 6.05488C11.1033 6.05488 10.8616 6.29666 10.8616 6.59482C10.8616 6.89298 11.1033 7.13475 11.4015 7.13475C11.6997 7.13475 11.9414 6.89298 11.9414 6.59482C11.9414 6.29666 11.6997 6.05488 11.4015 6.05488ZM8.99895 6.68625C7.72251 6.68625 6.68784 7.72142 6.68784 8.99787C6.68784 10.2743 7.72251 11.309 8.99895 11.309C10.2754 11.309 11.3096 10.2743 11.3096 8.99787C11.3096 7.72142 10.2754 6.68625 8.99895 6.68625ZM8.99895 7.49742C10.9824 7.49742 10.985 10.4983 8.99895 10.4983C7.01597 10.4983 7.01292 7.49742 8.99895 7.49742Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2202_19554"
        x1="1.74444"
        y1="16.2871"
        x2="17.2755"
        y2="2.84248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDD55" />
        <stop offset="0.5" stopColor="#FF543E" />
        <stop offset="1" stopColor="#C837AB" />
      </linearGradient>
    </defs>
  </svg>
)

export default Instagram
