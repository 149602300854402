import React, { SVGProps } from 'react'

const Helthjem = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 68 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1421_173)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0291 4.17834H19.2145V8.85407H19.2408C19.39 8.52043 19.6491 8.2505 20.0179 8.04406C20.3867 7.83784 20.8125 7.73455 21.2955 7.73455C21.5939 7.73455 21.8816 7.78064 22.1582 7.87284C22.4348 7.96503 22.6761 8.10784 22.8826 8.30089C23.0888 8.49416 23.2534 8.74211 23.3765 9.04504C23.4993 9.34797 23.5609 9.70588 23.5609 10.1185V14.1356H22.3755V10.4477C22.3755 10.158 22.336 9.90995 22.257 9.70351C22.1779 9.49737 22.0726 9.33044 21.9409 9.20309C21.8091 9.0759 21.6577 8.9837 21.4865 8.92643C21.3152 8.86953 21.1374 8.8409 20.953 8.8409C20.7071 8.8409 20.4789 8.88033 20.2682 8.95943C20.0574 9.03846 19.873 9.16351 19.715 9.3348C19.5569 9.50595 19.4339 9.72334 19.3462 9.98668C19.2583 10.2502 19.2145 10.5619 19.2145 10.9219V14.1356H18.0291V4.17834Z"
        fill="#3F4240"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9882 10.5004C29.9793 10.2369 29.9376 9.99565 29.8631 9.77604C29.7884 9.55665 29.6764 9.36567 29.5272 9.20311C29.3778 9.04069 29.1914 8.91335 28.9674 8.82115C28.7436 8.72888 28.4822 8.68278 28.1838 8.68278C27.8941 8.68278 27.6284 8.73784 27.387 8.84749C27.1453 8.95737 26.9412 9.09996 26.7745 9.27555C26.6076 9.45128 26.4782 9.64662 26.386 9.86165C26.2938 10.0769 26.2477 10.2897 26.2477 10.5004H29.9882ZM26.2477 11.4487C26.2477 11.721 26.3069 11.969 26.4254 12.1929C26.544 12.4168 26.6998 12.6077 26.8931 12.7658C27.0861 12.9239 27.31 13.0469 27.5647 13.1345C27.8193 13.2224 28.0827 13.2663 28.355 13.2663C28.7238 13.2663 29.0442 13.1806 29.3164 13.0095C29.5885 12.8383 29.8388 12.6123 30.0673 12.3312L30.9629 13.0161C30.3043 13.8679 29.3824 14.2936 28.1969 14.2936C27.7052 14.2936 27.2595 14.2101 26.8601 14.0434C26.4605 13.8767 26.1226 13.6462 25.8459 13.3519C25.5694 13.0578 25.3564 12.7111 25.2072 12.3114C25.0578 11.9119 24.9833 11.4796 24.9833 11.0141C24.9833 10.5488 25.0643 10.1164 25.227 9.71677C25.3893 9.31735 25.6132 8.97054 25.8987 8.67627C26.1839 8.38215 26.5242 8.15173 26.9194 7.9848C27.3145 7.8181 27.7446 7.73456 28.2102 7.73456C28.7633 7.73456 29.2309 7.83127 29.6128 8.02432C29.9947 8.21751 30.3086 8.47005 30.5546 8.78157C30.8002 9.09337 30.9781 9.4447 31.088 9.83531C31.1977 10.2261 31.2526 10.6235 31.2526 11.0273V11.4487H26.2477Z"
        fill="#3F4240"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.7672 14.1356H33.9526V4.17834H32.7672V14.1356Z"
        fill="#3F4240"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1813 8.91984H37.4822V11.7516C37.4822 11.9274 37.4866 12.1007 37.4954 12.2719C37.504 12.4431 37.5369 12.5969 37.5942 12.7329C37.6512 12.8691 37.7391 12.9788 37.8576 13.0621C37.9762 13.1457 38.1495 13.1872 38.3779 13.1872C38.5182 13.1872 38.6631 13.1741 38.8125 13.1478C38.9617 13.1214 39.0977 13.0732 39.2207 13.0029V14.0829C39.0802 14.1619 38.8981 14.2166 38.6742 14.2474C38.4503 14.2782 38.2768 14.2936 38.1539 14.2936C37.6973 14.2936 37.344 14.2298 37.0937 14.1026C36.8434 13.9754 36.659 13.8108 36.5406 13.6087C36.422 13.4068 36.3516 13.1806 36.3298 12.9304C36.3078 12.6802 36.2969 12.4279 36.2969 12.173V8.91984H34.9271V7.89259H36.2969V6.14087H37.4822V7.89259H39.1813V8.91984Z"
        fill="#3F4240"
      />
      <mask
        id="mask0_1421_173"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="40"
        y="4"
        width="10"
        height="14"
      >
        <path d="M40.7091 4.17834H49.5862V17.2921H40.7091V4.17834Z" fill="#F2B133" />
      </mask>
      <g mask="url(#mask0_1421_173)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.7091 4.17834H41.8945V8.85407H41.9208C42.07 8.52043 42.3291 8.2505 42.6979 8.04406C43.0667 7.83784 43.4925 7.73454 43.9754 7.73454C44.2739 7.73454 44.5615 7.78064 44.8381 7.87284C45.1147 7.96503 45.3561 8.10784 45.5625 8.30089C45.7687 8.49416 45.9334 8.74211 46.0564 9.04504C46.1793 9.34797 46.2408 9.70588 46.2408 10.1185V14.1356H45.0555V10.4477C45.0555 10.158 45.0159 9.90995 44.9369 9.70351C44.8579 9.49737 44.7525 9.33044 44.6208 9.20309C44.4891 9.0759 44.3376 8.9837 44.1664 8.92643C43.9952 8.86953 43.8174 8.8409 43.633 8.8409C43.3871 8.8409 43.1589 8.88033 42.9481 8.95943C42.7374 9.03846 42.553 9.16351 42.3949 9.3348C42.2369 9.50595 42.1138 9.72334 42.0262 9.98668C41.9383 10.2502 41.8945 10.5619 41.8945 10.9219V14.1356H40.7091V4.17834Z"
          fill="#F2B133"
        />
      </g>
      <mask
        id="mask1_1421_173"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="40"
        y="4"
        width="10"
        height="14"
      >
        <path d="M40.7091 4.17834H49.5862V17.2921H40.7091V4.17834Z" fill="#F2B133" />
      </mask>
      <g mask="url(#mask1_1421_173)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.8477 5.65358C47.8477 5.4165 47.9332 5.21235 48.1045 5.04113C48.2757 4.86991 48.4799 4.7843 48.7169 4.7843C48.954 4.7843 49.1582 4.86991 49.3294 5.04113C49.5006 5.21235 49.5862 5.4165 49.5862 5.65358C49.5862 5.89065 49.5006 6.0948 49.3294 6.26595C49.1582 6.43724 48.954 6.52285 48.7169 6.52285C48.4799 6.52285 48.2757 6.43724 48.1045 6.26595C47.9332 6.0948 47.8477 5.89065 47.8477 5.65358ZM49.3096 7.89262V15.0311C49.3096 15.1893 49.2985 15.3977 49.2767 15.6568C49.2547 15.9157 49.1845 16.1659 49.066 16.4076C48.9474 16.649 48.7585 16.8574 48.4996 17.0332C48.2406 17.2087 47.874 17.2965 47.3998 17.2965C47.2682 17.2965 47.1384 17.2877 47.0113 17.2703C46.8841 17.2526 46.759 17.2219 46.636 17.1781L46.7676 16.0849C46.838 16.1112 46.9171 16.1353 47.0047 16.1573C47.0926 16.1791 47.176 16.1902 47.2549 16.1902C47.5797 16.1902 47.8059 16.087 47.9332 15.8807C48.0605 15.6743 48.1242 15.3737 48.1242 14.9785V7.89262H49.3096Z"
          fill="#F2B133"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.829 10.5004C55.8202 10.2369 55.7784 9.99565 55.7039 9.77604C55.6292 9.55665 55.5172 9.36567 55.3681 9.20311C55.2186 9.04069 55.0322 8.91335 54.8082 8.82115C54.5844 8.72888 54.3231 8.68278 54.0246 8.68278C53.7349 8.68278 53.4692 8.73784 53.2278 8.84749C52.9861 8.95737 52.7821 9.09996 52.6154 9.27555C52.4484 9.45128 52.319 9.64662 52.2268 9.86165C52.1346 10.0769 52.0885 10.2897 52.0885 10.5004H55.829ZM52.0885 11.4487C52.0885 11.721 52.1477 11.969 52.2663 12.1929C52.3848 12.4168 52.5406 12.6077 52.7338 12.7658C52.9269 12.9239 53.1508 13.0469 53.4055 13.1345C53.6602 13.2224 53.9236 13.2663 54.1959 13.2663C54.5646 13.2663 54.885 13.1806 55.1573 13.0095C55.4293 12.8383 55.6797 12.6123 55.9081 12.3312L56.8036 13.0161C56.1451 13.8679 55.2232 14.2936 54.0377 14.2936C53.546 14.2936 53.1004 14.2101 52.701 14.0434C52.3013 13.8767 51.9633 13.6462 51.6868 13.3519C51.4102 13.0578 51.1972 12.7111 51.048 12.3114C50.8986 11.9119 50.8241 11.4796 50.8241 11.0141C50.8241 10.5488 50.9052 10.1164 51.0678 9.71677C51.2301 9.31735 51.454 8.97054 51.7395 8.67627C52.0247 8.38215 52.3651 8.15173 52.7602 7.9848C53.1554 7.8181 53.5854 7.73456 54.051 7.73456C54.6042 7.73456 55.0717 7.83127 55.4536 8.02432C55.8356 8.21751 56.1494 8.47005 56.3954 8.78157C56.6411 9.09337 56.8189 9.4447 56.9288 9.83531C57.0385 10.2261 57.0934 10.6235 57.0934 11.0273V11.4487H52.0885Z"
        fill="#F2B133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.5158 7.89261H59.6222V8.86725H59.6485C59.6748 8.77957 59.7428 8.66969 59.8526 8.5379C59.9624 8.40627 60.1029 8.28115 60.2742 8.16261C60.4453 8.04407 60.6473 7.94322 60.88 7.85961C61.1126 7.77629 61.3694 7.73456 61.6505 7.73456C62.1158 7.73456 62.5066 7.83127 62.8227 8.02432C63.1388 8.21751 63.3977 8.50727 63.5998 8.89359C63.8016 8.50727 64.0871 8.21751 64.4559 8.02432C64.8247 7.83127 65.1978 7.73456 65.5754 7.73456C66.0582 7.73456 66.4533 7.81351 66.7608 7.97163C67.068 8.12968 67.3095 8.33834 67.4852 8.59725C67.6607 8.8563 67.7815 9.15042 67.8473 9.47969C67.9133 9.80897 67.9461 10.1493 67.9461 10.5004V14.1356H66.7608V10.6585C66.7608 10.4213 66.7454 10.1931 66.7147 9.9736C66.6839 9.75421 66.6202 9.56101 66.5237 9.39408C66.427 9.22738 66.291 9.09337 66.1154 8.99237C65.9397 8.89152 65.7071 8.84091 65.4173 8.84091C64.8465 8.84091 64.4382 9.01664 64.1925 9.36774C63.9465 9.71899 63.8237 10.1712 63.8237 10.7243V14.1356H62.6384V10.8692C62.6384 10.5708 62.6229 10.2985 62.5923 10.0526C62.5613 9.80689 62.5001 9.59387 62.4078 9.41377C62.3156 9.23396 62.1839 9.09337 62.0127 8.99237C61.8414 8.89152 61.611 8.84091 61.3212 8.84091C61.1105 8.84091 60.9063 8.88271 60.7088 8.96603C60.5113 9.04957 60.3378 9.17469 60.1885 9.3414C60.0391 9.50833 59.9206 9.72128 59.833 9.98019C59.7451 10.2393 59.7012 10.5445 59.7012 10.8956V14.1356H58.5158V7.89261Z"
        fill="#F2B133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74769 7.68475L0.398011 5.15587C0.211991 5.08181 0 5.21618 0 5.42587V9.98143C0 10.1006 0.0727356 10.2077 0.183578 10.2517L6.53325 12.7657C6.72216 12.8405 6.93075 12.7021 6.93075 12.4956V7.95475C6.93075 7.83577 6.85823 7.72885 6.74769 7.68475Z"
        fill="#F2B133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74755 13.3137L3.10411 11.8645C2.90285 11.7873 2.70618 11.9381 2.70618 12.1345V14.9398C2.70618 15.06 2.78032 15.1679 2.89264 15.2109L6.53615 16.6088C6.72513 16.6813 6.93076 16.543 6.93076 16.3376V13.5836C6.93076 13.4646 6.85817 13.3576 6.74755 13.3137Z"
        fill="#F2B133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05887 11.4574L0.398085 10.7955C0.203482 10.7178 0 10.8636 0 11.0655V13.8706C0 13.9894 0.0725136 14.0963 0.182986 14.1403L1.84377 14.8023C2.02942 14.8757 2.24185 14.7426 2.24185 14.5323V11.7273C2.24185 11.6083 2.16934 11.5014 2.05887 11.4574Z"
        fill="#F2B133"
      />
      <mask
        id="mask2_1421_173"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13"
        height="17"
      >
        <path d="M0.413025 0.569946H12.3318V16.6499H0.413025V0.569946Z" fill="#3F4240" />
      </mask>
      <g mask="url(#mask2_1421_173)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.904 5.40089L7.54816 7.73649C7.45381 7.78718 7.39499 7.88559 7.39499 7.99258V16.3596C7.39499 16.5841 7.63465 16.7163 7.82275 16.6157L12.1785 14.2801C12.2729 14.2293 12.3318 14.131 12.3318 14.024V5.65698C12.3318 5.43744 12.0969 5.29729 11.904 5.40089Z"
          fill="#3F4240"
        />
      </g>
      <mask
        id="mask3_1421_173"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="13"
        height="17"
      >
        <path d="M0.413025 0.569946H12.3318V16.6499H0.413025V0.569946Z" fill="#3F4240" />
      </mask>
      <g mask="url(#mask3_1421_173)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1724 2.83002L3.90931 0.590757C3.78892 0.542587 3.65107 0.579732 3.5716 0.682213L0.474382 4.32069C0.365833 4.44796 0.39839 4.68918 0.595435 4.76843L6.94511 7.3229C7.07837 7.37648 7.21585 7.31935 7.28445 7.22967L10.3015 3.2782C10.4271 3.11401 10.3419 2.89033 10.1724 2.83002Z"
          fill="#3F4240"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1421_173">
        <rect width="68" height="16.7965" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default Helthjem
