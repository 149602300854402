'use client'

import { Container, theme } from '@hermes/web-components'
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar'
import React, { useMemo } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import { removeLocaleFromPathname } from '@lib/utils/localeHelpers'
import { usePathname } from 'next/navigation'

const flowLayoutPaths = new Set([
  '/request/{{sellerUuid}}/more-from-seller',
  '/books/{{bookSlug}}/sellers',
  '/request/{{sellerUuid}}/checkout',
  '/request/accept/{{requestUuid}}'
])

const isPathMatchingPattern = (path: string, patterns: string[]): boolean =>
  patterns.some((pattern) => {
    const regexPattern = pattern.replace(/\{\{[^}]+\}\}/g, '([^/]+)')
    const regex = new RegExp(`^${regexPattern}$`)
    return regex.test(path)
  })

export default function ClientSideLayout({ children }: { children: React.ReactNode }) {
  const pathName = usePathname()
  const pathNameWithoutLocale = removeLocaleFromPathname(pathName)

  const isFlowLayout = useMemo(
    () => isPathMatchingPattern(pathNameWithoutLocale, Array.from(flowLayoutPaths)),
    [pathNameWithoutLocale]
  )

  const MemoizedHeader = useMemo(() => (!isFlowLayout ? <Header /> : null), [isFlowLayout])

  return (
    <>
      <ProgressBar
        color={theme.palette.primary.main}
        startPosition={0.3}
        stopDelay={200}
        height={'3px'}
        options={{ showSpinner: false }}
      />
      {MemoizedHeader}
      {children}
      {!isFlowLayout && (
        <Container maxWidth="xl">
          <Footer />
        </Container>
      )}
    </>
  )
}
