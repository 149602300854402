import React, { SVGProps } from 'react'

const Postnord = (props?: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 68 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1421_172)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.056665 3.10664C0.981352 3.10358 1.90592 3.10369 2.83061 3.09417C2.98871 3.09259 3.02215 3.15707 3.01478 3.29511C3.00617 3.45469 3.01274 3.61517 3.01274 3.78483C3.04742 3.77837 3.0804 3.78233 3.09683 3.76771C5.05149 2.03496 8.03057 2.72663 8.96194 5.17565C9.62551 6.92065 9.5005 8.61339 8.23933 10.0872C6.90891 11.6418 4.37943 11.7161 3.11723 10.7121C3.09763 10.6965 3.06907 10.6922 3.00333 10.6663C3.00333 11.5993 3.00333 12.5096 3.00333 13.42C2.108 13.42 1.21267 13.4135 0.317445 13.425C0.116618 13.4275 0.0496383 13.398 0.050205 13.1719C0.0594983 9.81677 0.056665 6.46176 0.056665 3.10664ZM2.93828 7.08215C2.9257 8.04616 3.59312 8.74191 4.54716 8.75914C5.457 8.77557 6.16182 8.10022 6.17655 7.19786C6.19344 6.15429 5.56705 5.45423 4.60315 5.43949C3.62757 5.42453 2.95131 6.09184 2.93828 7.08215Z"
        fill="#0498BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.0567 11.1533C67.2279 11.0986 66.398 11.1446 65.5689 11.1296C65.484 11.1281 65.3979 11.1207 65.3143 11.1314C65.1304 11.1551 65.0853 11.0685 65.098 10.9023C65.11 10.7447 65.1005 10.5855 65.1005 10.3875C64.9323 10.5271 64.7971 10.6523 64.6494 10.76C63.2639 11.7696 61.0662 11.5325 59.8899 10.2495C58.4194 8.64524 58.4264 5.72804 59.872 4.10839C61.1825 2.64016 63.6899 2.45724 65.0017 3.50127C65.0206 3.51634 65.05 3.51815 65.1006 3.53481C65.1006 2.82376 65.1115 2.1288 65.0941 1.43463C65.0889 1.22247 65.1701 1.16331 65.354 1.12909C66.1411 0.982548 66.9262 0.824788 67.7121 0.671561C67.7572 0.662721 67.8073 0.666348 67.8301 0.613194C68.024 0.570354 68.0628 0.645834 68.0623 0.839861C68.0545 4.27771 68.0567 7.71545 68.0567 11.1533ZM61.9368 7.09167C61.936 8.07812 62.5899 8.76503 63.5344 8.77002C64.5002 8.77535 65.1713 8.09285 65.1752 7.10187C65.1787 6.1374 64.5062 5.4625 63.5419 5.46295C62.5882 5.46341 61.9375 6.1238 61.9368 7.09167Z"
        fill="#0498BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4504 2.81982C48.3521 2.83365 49.1097 2.96002 49.8312 3.26908C51.5311 3.99702 52.4252 5.54844 52.2773 7.39588C52.1005 9.60475 50.5977 10.8869 48.616 11.2603C47.4388 11.4823 46.2827 11.3909 45.1753 10.9193C43.4254 10.1744 42.6541 8.68754 42.7459 6.89835C42.877 4.34676 44.7372 3.14792 46.7256 2.88227C47.0055 2.84487 47.2888 2.83331 47.4504 2.81982ZM45.8768 7.10609C45.877 8.06738 46.5745 8.77311 47.5106 8.7594C48.4604 8.74546 49.1196 8.07294 49.1205 7.11697C49.1214 6.12032 48.4558 5.43556 47.4897 5.43941C46.5383 5.44326 45.8763 6.127 45.8768 7.10609Z"
        fill="#0498BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6399 2.82125C15.8762 2.85196 17.0316 3.13122 18.0049 3.94484C18.8153 4.62223 19.2312 5.50793 19.3221 6.54901C19.4259 7.7381 19.2069 8.84435 18.4096 9.77765C17.7265 10.5772 16.8343 11.0173 15.8186 11.2302C14.6144 11.4828 13.4344 11.4068 12.2926 10.9393C10.3979 10.1637 9.64749 8.50424 9.83098 6.64319C10.0365 4.56069 11.5003 3.31708 13.4301 2.94059C13.829 2.86273 14.2322 2.82012 14.6399 2.82125ZM14.5908 5.43948C13.625 5.43177 12.9499 6.10656 12.9306 7.09902C12.9125 8.03164 13.6281 8.75674 14.5675 8.75742C15.5022 8.7581 16.1851 8.07323 16.1957 7.12497C16.2063 6.15869 15.5286 5.44696 14.5908 5.43948Z"
        fill="#0498BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7924 3.74711C37.7664 2.79669 38.9118 2.64301 40.1407 2.97281C41.2551 3.2719 41.778 4.10853 41.985 5.18599C42.0404 5.47419 42.0575 5.76546 42.0572 6.05899C42.0551 7.65484 42.0524 9.25057 42.0605 10.8463C42.0616 11.0588 42.0191 11.1398 41.785 11.1356C40.9733 11.1206 40.1609 11.1226 39.3491 11.1347C39.1294 11.138 39.0603 11.0811 39.0615 10.8525C39.0675 9.52155 39.0577 8.19034 39.0497 6.85924C39.0489 6.72811 39.0342 6.59529 39.0096 6.46643C38.872 5.74869 38.5846 5.50989 37.8995 5.53562C37.2402 5.56033 36.8374 5.91778 36.7793 6.57613C36.7137 7.31983 36.7608 8.06703 36.7542 8.81265C36.7481 9.50183 36.746 10.1912 36.7572 10.8803C36.7605 11.0816 36.6952 11.1363 36.4996 11.1339C35.6687 11.1245 34.8376 11.1252 34.0068 11.1337C33.8247 11.1355 33.7681 11.088 33.7686 10.8978C33.7751 8.37655 33.7757 5.85545 33.768 3.33446C33.7674 3.124 33.8411 3.09113 34.0271 3.09317C34.8391 3.10247 35.6514 3.10609 36.4631 3.09159C36.7004 3.08728 36.7853 3.15551 36.7571 3.39101C36.7444 3.49743 36.7284 3.6136 36.7924 3.74711Z"
        fill="#0598BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9377 2.82849C24.9243 2.86226 25.8242 2.93854 26.7109 3.1465C26.8972 3.19025 26.9329 3.24771 26.8863 3.43074C26.7353 4.02416 26.5937 4.62052 26.4717 5.2205C26.4262 5.44502 26.3441 5.4517 26.1497 5.39356C25.2312 5.11907 24.2986 4.94272 23.3331 5.08348C23.2591 5.09425 23.1863 5.11567 23.1143 5.13698C22.9372 5.18911 22.7987 5.29292 22.8007 5.48888C22.8029 5.69174 22.9602 5.77992 23.1348 5.81947C23.7696 5.96329 24.4236 6.00001 25.0552 6.16378C25.3936 6.2515 25.7259 6.3544 26.0421 6.5048C26.9173 6.92096 27.3071 7.60198 27.2782 8.56951C27.244 9.71542 26.6575 10.4515 25.6387 10.8903C24.8175 11.2438 23.9448 11.3328 23.0658 11.3522C21.9658 11.3764 20.8843 11.2303 19.8234 10.9308C19.6505 10.882 19.6036 10.8277 19.6551 10.6424C19.8289 10.016 19.9878 9.38516 20.1362 8.7522C20.1841 8.54774 20.2571 8.53301 20.441 8.60384C21.4186 8.98 22.4255 9.20054 23.4822 9.12166C23.6251 9.1109 23.764 9.08551 23.8936 9.02748C24.0395 8.96243 24.1424 8.86224 24.1407 8.68374C24.139 8.50377 24.034 8.4088 23.8828 8.35088C23.5536 8.22463 23.204 8.19686 22.859 8.15142C22.2592 8.07254 21.6663 7.96453 21.1027 7.73786C19.4736 7.08268 19.1347 4.98613 20.4703 3.82979C21.2039 3.19467 22.0997 2.9892 23.0293 2.88652C23.3571 2.85048 23.688 2.84379 23.9377 2.82849Z"
        fill="#0598BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8232 6.19703C27.8232 4.6481 27.828 3.09929 27.8179 1.55036C27.8164 1.33548 27.874 1.28459 28.0837 1.28788C28.9051 1.30057 29.7271 1.30227 30.5484 1.28709C30.7741 1.28289 30.8137 1.36121 30.8074 1.56147C30.7937 1.99553 30.8128 2.43051 30.7989 2.86457C30.7926 3.05917 30.8645 3.10235 31.0438 3.10042C31.7899 3.09226 32.5361 3.10155 33.2821 3.09396C33.4787 3.09192 33.5405 3.12252 33.4307 3.32142C33.0432 4.02284 32.6636 4.72868 32.2887 5.43701C32.2211 5.56451 32.1392 5.60724 31.999 5.60293C31.6876 5.59341 31.3749 5.61415 31.0643 5.59523C30.8501 5.58219 30.797 5.64951 30.7981 5.86156C30.8073 7.49537 30.8032 9.1293 30.8031 10.7632C30.8031 11.1293 30.803 11.1294 30.44 11.1295C29.6656 11.1295 28.8909 11.12 28.1168 11.1353C27.8843 11.1399 27.8157 11.0832 27.8174 10.8436C27.8286 9.29488 27.8232 7.74595 27.8232 6.19703Z"
        fill="#0598BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9394 3.95734C56.618 3.04399 57.5189 2.92283 58.4899 2.99752C58.6664 3.01101 58.7517 3.07878 58.7438 3.27961C58.7143 4.04279 58.6985 4.80655 58.6851 5.57019C58.6821 5.75118 58.6252 5.79731 58.4459 5.75095C58.1063 5.66323 57.758 5.63615 57.4071 5.66595C56.4278 5.74925 55.9447 6.26605 55.9408 7.24922C55.9359 8.43854 55.9332 9.62786 55.9447 10.817C55.9471 11.0542 55.9046 11.143 55.6407 11.1366C54.8389 11.1168 54.0363 11.1248 53.2341 11.1328C53.0519 11.1346 52.9685 11.1069 52.9692 10.892C52.9766 8.3719 52.9757 5.85171 52.9703 3.33174C52.9698 3.14984 53.0163 3.09068 53.2047 3.09283C54.0445 3.10258 54.8847 3.10235 55.7245 3.09295C55.905 3.09079 55.954 3.15041 55.9424 3.3195C55.9293 3.50627 55.9394 3.69463 55.9394 3.95734Z"
        fill="#0498BA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1421_172">
        <rect width="68" height="12.92" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default Postnord
